import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        </header>
        <h1>Greetings!</h1>
        <body>  
         <h2>Are you getting attacked by this server? Don't panic, there are a few reasons why this is happening:</h2>
         <div id ="wrap">
           <div id="content">
              <br></br> 1. We are conducting an authorized Penetration test or Phishing engament that has been requested by your IT department.
              <br></br> 2. We are using this host for a bug bounty project, from ex, H1 or Bugcrowd etc.
              <br></br>3. We accidentally stepped out of scope while doing 1) or 2)
              <br></br> 4. Security research (Recently, Payloads were fired against a few thousand domains in order to see what comes back) In any case, there are no evil intentions behind these attacks.
              </div>
         </div>
        <p><h3>If you think that none of these scenarios can be applied to your observations or if you simply want more information feel free to contact: abuse@0x4a.se</h3>
        <a
          className="App-link"
          href="https://0x4a.se"
          target="_blank"
          rel="noopener noreferrer"
        >
        <h3>More Information can be found at 0x4a(dot)se</h3> 
        </a>
        </p>
        </body>
    </div>
  );
}

export default App;
